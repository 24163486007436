// import the styles
import '@/pcss/app.pcss';

// Accept HMR as per https://vitejs.dev/guide/api-hmr.html#accepting-updates
/// <reference types="vite/client" />
if (import.meta.hot) {
    import.meta.hot.accept(() => {
        console.log('HMR update');
    });
}

console.log('Hello, Vite!');

// on the our-work page, run the gallery.js file
// if page contains a div with the id of 'our-work'
if (document.getElementById('our-work')){
    import ('./project-filter').then(({ default: projectFilter }) => {
        projectFilter();
    }
    );

    import('./gallery').then(({ default: gallery }) => {
        gallery();
    });
}
